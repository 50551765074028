<template>
  <div v-if="!requireContext || context ">
    <div>
      <Facets :facets-values="facetValues" :facets-config="facetsConfig" facets-horizontal @filtersUpdate="onFiltersUpdate" />
    </div>
    <div class="pa-4">
      <v-expansion-panels
        multiple
        :value="[1,0]"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            Non sélectionné ({{ showUsers.length }})
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :headers="headers"
              :items="showUsers"
              :page.sync="usersPageValue"
              hide-default-footer
              :loading="loading"
              sort-by="name"
              item-key="id"
              @page-count="usersPageCount = $event"
            >
              <template #[`header.actions`]>
                <v-icon :disabled="disableSelect" @click="addAll">
                  mdi-account-multiple-plus
                </v-icon>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-icon
                  :disabled="disableSelect"
                  @click="addUser(item)"
                >
                  mdi-plus
                </v-icon>
              </template>
            </v-data-table>
            <v-pagination v-model="usersPageValue" :length="usersPageCount" :total-visible="10" class="pb-6" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="targeting-expansion-panel">
            Sélectionné ({{ selectedUsers.length }})
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :headers="headers"
              :items="selectedUsers"
              :page.sync="selectedPageValue"
              hide-default-footer
              item-key="id"
              sort-by="name"
              no-data-text="Aucun utilisateur sélectionné"
              @page-count="selectedPageCount = $event"
            >
              <template #[`header.actions`]>
                <v-icon :disabled="disableSelect" @click="removeAll">
                  mdi-account-multiple-minus
                </v-icon>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-icon
                  :disabled="disableSelect"
                  @click="removeUser(item)"
                >
                  mdi-minus
                </v-icon>
              </template>
            </v-data-table>
            <v-pagination v-model="selectedPageValue" :length="selectedPageCount" :total-visible="10" class="pb-6" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div class="ma-4 inputIframe">
      <iframe ref="inputIframe" :src="helloUrl" style="width: 100%;" :style=" increaseIframeHeight ? 'height: 100vh;' : 'height: 100%;'" frameBorder="0" class="absolute"></iframe>
    </div>
    <div class="toast">
      <v-alert
        :value="successAlert"
        dismissible
        type="success"
        transition="scroll-y-transition"
      >
        Message envoyé au(x) destinataire(s)
      </v-alert>
    </div>
    <div class="toast">
      <v-alert
        :value="loadingAlert"
        dismissible
        type="warning"
        transition="scroll-y-transition"
      >
        Envoi du dernier message ...
      </v-alert>
    </div>
    <div class="toast">
      <v-alert
        :value="usersAlert"
        dismissible
        type="error"
        transition="scroll-y-transition"
      >
        Aucun utilisateur sélectionné
      </v-alert>
    </div>
  </div>
  <div v-else>
    <v-card class="ma-6" color="warning" rounded="lg" outlined elevation="4">
      <v-card-text class="white--text text-h6">
        <div>
          Un contexte doit être sélectionné.
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import Facets from '@/components/Facets';
import { requests } from '@/mixins/index';
import store from '@/store';
import { getTargetingConfig } from '@/configs/helper';
import transformFilters from '@/utils/facets';

const config = require('@/configs/targetingFacets.json');

const inputIframe = ref(null);

const filters = ref([]);

const users = ref([]);
const usersPageValue = ref(1);
const usersPageCount = ref(1);

const selectedUsers = ref([]);
const selectedPageValue = ref(1);
const selectedPageCount = ref(0);

const successAlert = ref(false);
const loadingAlert = ref(false);
const usersAlert = ref(false);
const increaseIframeHeight = ref(false);
const disableSelect = ref(true);

const loading = ref(false);
const startDate = new Date();

let abortController = null;
const abortMessage = 'Resend Request';

startDate.setFullYear(2020);
startDate.setMonth(1);
startDate.setDate(1);

const headers = ref([
  { text: 'Actions', value: 'actions', sortable: false },
  {
    text: 'Nom',
    align: 'start',
    value: 'firstname'
  },
  { text: 'Prénom', value: 'lastname' },
  { text: 'Age', value: 'age' },
  { text: 'Id Contact', value: 'metadata.trackId', sortable: false }
]);

// const aggregates = [{ type: 'min', field: 'birth_date', name: 'minDate' }, { type: 'max', field: 'createdAt', name: 'maxDate' }];
const workspace = computed(() => store.getters.getActiveBrand);
const context = computed(() => store.getters.getActiveContext);
const helloUrl = computed(() => `${store.getters.getBrandUrl}/targeting?from=${window.location.href}&token=${store.state.token}`);
const showUsers = computed(() => users.value.filter(u => !selectedUsers.value.some(sU => u.id === sU.id)));
const destination = computed(() => store.getters.getActiveDestination);
const requireContext = computed(() => !!(config[workspace.value].requireContext));
const facetsConfig = computed(() => (requireContext.value ? getTargetingConfig(context.value) : getTargetingConfig(workspace.value)));

const facetValues = ref({});

const configureFacetValues = () => {
  const values = {};
  Object.entries(facetsConfig.value).forEach(([name, facet]) => {
    if (facet.aggregated) {
      values[name] = { data: [] };
    }
    if (facet.values) {
      values[name] = { data: facet.values };
    }
  });
  facetValues.value = values;
};

const updateIframeUsers = () => {
  inputIframe.value.contentWindow.postMessage({ users: selectedUsers.value.map(u => u.id) }, '*');
};

const addAll = () => {
  selectedUsers.value.push(...showUsers.value);
  updateIframeUsers();
};

const addUser = user => {
  selectedUsers.value.push(user);
  updateIframeUsers();
};

const removeAll = () => {
  selectedUsers.value = [];
  updateIframeUsers();
};

const removeUser = user => {
  const index = selectedUsers.value.findIndex(u => u.id === user.id);
  selectedUsers.value.splice(index, 1);
  updateIframeUsers();
};

const getAge = dateString => {
  const birthday = new Date(dateString);
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const getUsers = async () => {
  loading.value = true;

  abortController = new AbortController();
  const { signal } = abortController;

  // const date = filters.value.find(f => f.field === 'date');

  const groupsAnd = [
    { field: 'metadata.workspace', condition: { eq: store.getters.getActiveBrand } }];

  const participantsAnd = [];

  const usersAnd = [{ field: 'metadata.type', condition: { ne: 'collaborator' } }];

  if (requireContext.value) {
    groupsAnd.push({ field: 'metadata.context', condition: { eq: store.getters.getActiveContext } });
  }

  Object.entries(facetsConfig.value).forEach(([field, facet]) => {
    const filterValue = filters.value.find(f => f.field === field)?.value;
    const filter = transformFilters(facet, filterValue);

    if (filter) {
      switch (facet.filterOf) {
        case 'groups':
          groupsAnd.push(filter);
          break;
        case 'participants':
          participantsAnd.push(filter);
          break;
        case 'users':
          usersAnd.push(filter);
          break;
        default:
      }
    }
  });

  const response = await requests.getRoomsParticipantsUsers(groupsAnd, participantsAnd, usersAnd, signal).catch(r => {
    if (r === abortMessage) return { aborted: true };
    throw (r);
  });

  if (response.aborted) return;

  if (response?.data?.items) {
    users.value = response.data.items.map(u => ({ ...u, metadata: JSON.parse(u.metadata), age: getAge(u.birth_date) }));
  }

  usersPageValue.value = 1;

  abortController = null;
  loading.value = false;
};

const updateRequestFacets = async () => {
  // eslint-disable-next-line guard-for-in
  const groupsAggregates = [];
  const participantsAggregates = [];

  Object.entries(facetsConfig.value).forEach(([key, facet]) => {
    if (facet.aggregated) {
      if (facet.filterOf === 'groups') {
        groupsAggregates.push({ field: facet.filterField, name: key, type: facet.aggregated, size: 1000 });
      }
      if (facet.filterOf === 'participants') {
        participantsAggregates.push({ field: facet.filterField, name: key, type: facet.aggregated, size: 1000 });
      }
    }
  });

  const platformConversion = { crm_labalaguere: 'labalaguere', vacances: 'speedboat', loisir: 'alpha' };

  const participantsAnd = [{ field: 'metadata.workspace', condition: { eq: platformConversion[context.value || workspace.value] } }];

  if (participantsAggregates.length) {
    const resp = await requests.getParticipantsFacets(participantsAggregates, { and: participantsAnd });
    if (resp.data.aggregateItems?.length) {
      resp.data.aggregateItems.forEach(aggregate => {
        facetValues.value[aggregate.name].data = aggregate.result.buckets.map(b => ({ value: b.key }));
      });
    }
  }

  const groupsAnd = [{ field: 'metadata.workspace', condition: { eq: workspace.value } }];

  if (requireContext.value) {
    groupsAnd.push({ field: 'metadata.context', condition: { eq: context.value } });
  }

  if (groupsAggregates.length) {
    const resp = await requests.getRoomsFacets(groupsAggregates, { and: groupsAnd });
    if (resp.data.aggregateItems?.length) {
      resp.data.aggregateItems.forEach(aggregate => {
        facetValues.value[aggregate.name].data = aggregate.result.buckets.map(b => ({ value: b.key }));
      });
    }
  }
};

const onFiltersUpdate = value => {
  filters.value = value;
  if (abortController) {
    loading.value = false;
    abortController.abort(abortMessage);
  }
  if (value.length) getUsers();
  else {
    users.value = [];
  }
};
watch(
  destination,
  () => {
    if (filters.value.length) getUsers();
    else updateRequestFacets();
  }
);

watch(
  context,
  newContext => {
    if (filters.value.length) getUsers();
    else if (newContext) {
      configureFacetValues();
      updateRequestFacets();
    }
  }
);

onMounted(async () => {
  window.addEventListener('message', m => {
    if (m.data.inputLoaded) disableSelect.value = false;
    if (m.data.loading) loadingAlert.value = true; setTimeout(() => loadingAlert.value = false, 4000);
    if (m.data.usersMissing) usersAlert.value = true; setTimeout(() => usersAlert.value = false, 4000);
    if (m.data.success) successAlert.value = true; setTimeout(() => successAlert.value = false, 4000);
    if (Object.hasOwn(m.data, 'emojiPicker')) {
      increaseIframeHeight.value = m.data.emojiPicker;
    }
  }, false);
  if (!requireContext.value || context.value) {
    configureFacetValues();
    updateRequestFacets();
  }
});
</script>

<style scoped>
.toast {
  position: fixed;
  z-index: 2;
  top: 80px;
  right: 1rem;
}

.absolute {
  bottom: 0;
  position: absolute;
  z-index: 4;
}
.inputIframe {
  position:relative;
  overflow: visible;
  height: 12rem;
}
</style>
