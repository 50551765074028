<template>
  <div class="d-flex flex-wrap px-4">
    <div class="breadcrumbs d-flex pa-0 col-12">
      <div>
        <v-btn
          class="mt-3 mb-4 mr-2"
          fab
          dark
          x-small
          color="primary"
          @click="changeSideBar()"
        >
          <v-icon dark>
            {{ !showFacets ? "mdi-forwardburger" : "mdi-backburger" }}
          </v-icon>
        </v-btn>
        <v-expand-x-transition>
          <div v-show="showFacets" class="sidebar">
            <Facets :facets-values="facetValues" :facets-config="facetsConfig" :column-height="facetbarHeight - 0" @filtersUpdate="onFiltersUpdate" />
          </div>
        </v-expand-x-transition>
      </div>
      <div class="content pt-4 pl-4 flex-wrap col py-0" style="height: 100%">
        <v-card height="100%" class="d-flex flex-column">
          <div class="results">
            <v-divider />
          </div>
          <DashboardTable
            class="pt-2"
            view="reports"
            :show-list="reports"
            :page="page"
            :page-count="pageCount"
            @rowClick="reportClick"
            @rowOpenNewTab="reportClickNewTab"
            @update:page="getReports"
          />
        </v-card>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue2-helpers/vue-router';
import { format } from 'date-fns';
import Facets from '../components/Facets';
import DashboardTable from '@/components/DashboardTable.vue';
import { requests } from '@/mixins/requests';
import store from '@/store/index';
import { getFacetsConfiguration } from '../configs/helper';
import transformFilters from '@/utils/facets';

const typeLabels = require('@/configs/reportsTypeLabels.json');

const router = useRouter();

const facetsConfig = ref(getFacetsConfiguration('reports'));

const filters = ref([]);

const showFacets = ref(true);

const facetValues = ref({});

const facetbarHeight = 285;

const reports = ref([]);

const page = ref(1);

const pageCount = ref(1);

const changeSideBar = () => showFacets.value = !showFacets.value;

const reportClick = report => {
  router.push({ name: 'ReportInfo', params: { id: report.id } });
};

const reportClickNewTab = group => {
  const routeData = router.resolve({ name: 'ReportInfo', params: { id: group.id } });
  window.open(routeData.href, '_blank');
};

const configureFacetValues = () => {
  const values = {};
  Object.entries(facetsConfig.value).forEach(([name, facet]) => {
    if (facet.aggregated) {
      values[name] = { data: [] };
    }
    if (facet.values) {
      values[name] = { data: facet.values };
    }
  });
  facetValues.value = values;
};

const prepareReports = newReports => {
  if (newReports && newReports.length) {
    reports.value = newReports.map(i => ({
      id: i.tag ? i.tag : i.id,
      message: i.message,
      reportDate: format(new Date(i.createdAt), 'yyyy-MM-dd'),
      groupName: i.room ? i.room.name : '----------------',
      link: i.room ? `${store.getters.getBrandUrl}/${i.room.id}` : '----------------',
      departureDate: JSON.parse(i.room ? i.room.metadata : '{}').departure_date || '----------------',
      firstName: i.user ? i.user.firstname : 'AUTOMATIQUE',
      lastName: i.user ? i.user.lastname : '',
      type: i.type,
      typeLabel: typeLabels[i.type] || i.type
    }));
  } else {
    pageCount.value = 0;
    reports.value = [];
  }
};

const getReports = async newPage => {
  store.state.loading = true;
  page.value = newPage;
  const limit = 10;

  const aggregates = [];
  Object.entries(facetsConfig.value).forEach(([key, facet]) => {
    if (facet.aggregated) {
      aggregates.push({ field: facet.filterField, name: key, type: facet.aggregated, size: 1000 });
    }
  });

  const and = [{ field: 'metadata.workspace', condition: { eq: store.state.activeBrand } }];

  Object.entries(facetsConfig.value).forEach(([field, facet]) => {
    const filterValue = filters.value.find(f => f.field === field)?.value;
    const filter = transformFilters(facet, filterValue);

    if (filter) {
      and.push(filter);
    }
  });

  const filter = and.length ? { and: and } : null;

  const response = await requests.getReports(limit, null, limit * (page.value - 1), filter, aggregates).then(
    r => r
  ).catch(err => {
    store.state.loading = false;
    return err;
  });

  if (response.success) {
    pageCount.value = Math.ceil(response.data.total / limit);

    prepareReports(response.data.items);

    if (response.data.aggregateItems?.length) {
      response.data.aggregateItems.forEach(aggregate => {
        if (typeLabels && aggregate.name === 'type') {
          facetValues.value[aggregate.name].data = aggregate.result.buckets.map(b => ({ label: typeLabels[b.key], value: b.key }));
        } else {
          facetValues.value[aggregate.name].data = aggregate.result.buckets.map(b => ({ value: b.key }));
        }
      });
    }
  } else {
    prepareReports([]);
  }

  store.state.loading = false;
};

const onFiltersUpdate = value => {
  filters.value = value;
  getReports(1);
};

onMounted(async () => {
  configureFacetValues();
  await getReports(1);
});

</script>

<style lang="scss">
.breadcrumbs {
  min-height: 40px;
}
.sidebar {
  width: 330px;
  height: calc(100vh - 185px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.content {
  width: calc(100% - 330px);
  height: calc(100vh - 140px);
}
.results {
  max-height: calc(100vh - 275px);
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
